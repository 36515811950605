<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-6">
            <h1>Profile</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'Home'}">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Update Password</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <section class="content">
      <div class="container-fluid">
        <div class="card" id="users-add">
          <div class="overlay" v-if="is_loading">
            <i class="fas fa-2x fa-sync-alt fa-spin"></i>
          </div>

          <div class="card-header">
            <h3 class="card-title">Update Password</h3>
          </div>

          <form @submit.prevent="handleSubmission()" method="POST" novalidate>
            <div class="card-body">
              <div class="form-row">
                <div class="form-group required col-md-12">
                  <label for="old_password" class="control-label">Old Password</label>
                  <input autocomplete="off" type="password" id="old_password" name="old_password"
                         v-model="inputs.old_password"
                         v-validate="'required'" :class="{'has-error' : errors.has('old_password')}"
                         class="form-control" placeholder="old password">
                  <div class="help text-danger" v-show="errors.has('old_password')">
                    {{ errors.first('old_password') }}
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group required col-md-12">
                  <label for="password" class="control-label">Password</label>
                  <input autocomplete="off" type="password" id="password" name="password" v-model="inputs.password"
                         v-validate="'required'" :class="{'has-error' : errors.has('password')}"
                         class="form-control" placeholder="new password">
                  <div class="help text-danger" v-show="errors.has('password')">
                    {{ errors.first('password') }}
                  </div>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group required col-md-12">
                  <label for="password_confirmation" class="control-label">Confirm Password</label>
                  <input autocomplete="off" type="password" id="password_confirmation" name="password_confirmation"
                         v-model="inputs.password_confirmation"
                         v-validate="'required'" :class="{'has-error' : errors.has('password_confirmation')}"
                         class="form-control" placeholder="confirm password">
                  <div class="help text-danger" v-show="errors.has('password_confirmation')">
                    {{ errors.first('password_confirmation') }}
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <button type="submit" class="btn btn-site">Update Password</button>
            </div>
          </form>

        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {AuthAPI} from "@/services/api";

export default {
  name: 'ChangePassword',
  metaInfo: {
    title: 'Change Password | Dotlines'
  },
  components: {
    //
  },
  data: () => ({
    is_loading: false,
    inputs: {}
  }),
  mounted: function () {
    //
  },
  methods: {
    handleSubmission() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.is_loading = true;
          AuthAPI.changePassword(this.inputs).then(response => {
              this.errors.clear()
              this.$toaster.success(response.message)
            }).catch(error => {
              this.$setErrorsFromResponse(error.data);
            }).finally(() => {
              this.inputs = {};
              this.is_loading = false;
            });
        }
      })
    }
  },
}
</script>
